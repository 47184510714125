import styled from "styled-components";
import { Layout, Button, Input } from "antd";
import {
  HeartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Header: AntHeader } = Layout;
const { Search } = Input;

const Header = () => {
  return (
    <HeaderContainer>
      <Logo>
        <LogoText>YktExpress</LogoText>
      </Logo>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Каталог</div>
        <Search
          placeholder="Поиск"
          onSearch={() => {}}
          style={{ width: 200, margin: "0 16px" }}
        />
        <Button icon={<UserOutlined />} type="text" onClick={() => {}}>
          Войти
        </Button>
        <Button icon={<HeartOutlined />} type="text" onClick={() => {}}>
          Избранное
        </Button>
        <Button icon={<ShoppingOutlined />} type="text" onClick={() => {}}>
          Корзина
        </Button>
      </div>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled(AntHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff !important;
`;

const Logo = styled.div`
  flex: 0 0 auto;
  font-size: 24px;
  font-weight: bold;
  user-select: none;
`;

const LogoText = styled.span`
  background: linear-gradient(45deg, #a314e6, #e6141f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  cursor: pointer;
`;
