import styled from "styled-components";

const MobileAppDownloadContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-color: #f5f5f5;
    text-align: center;
    padding: 20px;
  }
`;

const Content = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
`;

const MobileApp = () => {
  const YktExpress = require("../assets/yktexpress.svg").default;

  return (
    <Content>
      <img
        src={YktExpress}
        alt="YktExpress"
        style={{
          width: 200,
          height: 200,
        }}
      />
      <MobileAppDownloadContainer>
        Скачайте наше приложение из{" "}
        <a href="https://apps.apple.com/ru/app/yktexpress/id1670006214">
          App Store
        </a>{" "}
        и{" "}
        <a href="https://play.google.com/store/apps/details?id=com.yktexpress.app">
          Google Play
        </a>
      </MobileAppDownloadContainer>
    </Content>
  );
};

export default MobileApp;
