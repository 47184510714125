import { gql } from "@apollo/client";

export const PROMOTIONS = gql`
  query PROMOTIONS($data: PromotionsInput) {
    promotions(data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const PROMOTION = gql`
  query PROMOTION($where: PromotionWhereInput!) {
    promotion(where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;
