import styled from "styled-components";

const Footer = () => {
  const GooglePlay = require("../assets/footer/google/googlePlay.svg").default;
  const AppStore = require("../assets/footer/apple/appleStore.svg").default;
  const QrCode = require("../assets/footer/qr/qrCode.svg").default;

  return (
    <FooterContainer>
      <GlassEffect>
        <UpperContainer>
          <LeftSection>
            <Title>{"Совершайте покупки\nв нашем приложении"}</Title>
            <Subtitle>
              Наведите камеру и скачайте бесплатное приложение в App Store и
              Google Play
            </Subtitle>
            <StoreIcons>
              <StyledLink href="https://apps.apple.com/ru/app/yktexpress/id1670006214">
                <img src={AppStore} alt="App Store" />
              </StyledLink>
              <StyledLink href="https://play.google.com/store/apps/details?id=com.yktexpress.app">
                <img src={GooglePlay} alt="Google Play" />
              </StyledLink>
            </StoreIcons>
          </LeftSection>
          <QrSection>
            <img
              src={QrCode}
              alt="Qr Code"
              style={{ maxWidth: "300px", maxHeight: "300px" }}
            />
            <QrSubtitle>{"наведите камеру,\n скачайте приложение"}</QrSubtitle>
          </QrSection>
        </UpperContainer>
        <LinkContainer>
          <Link>О нас</Link>
          <Link>Покупателям</Link>
          <Link>Партнерам</Link>
          <EmailContainer>
            <EmailText>Электронная почта</EmailText>
            <EmailLink>ykt.express.14@gmail.com</EmailLink>
          </EmailContainer>
        </LinkContainer>
        <LegalContainer>
          <LegalTextLeft href="/policy">
            Соглашение о конфиденциальности
          </LegalTextLeft>
        </LegalContainer>
      </GlassEffect>
    </FooterContainer>
  );
};

export default Footer;

const GlassEffect = styled.div`
  background: rgba(255, 255, 255, 0.75); // Adjust the alpha for transparency
  backdrop-filter: blur(10px); // This creates the frosted glass effect
  border-radius: 20px; // Optional: if you want rounded corners
  padding: 60px; // Adjust as necessary
  margin: 20px 0; // Gives some space around the container
  width: 100%; // Adjust width as necessary
`;

const FooterContainer = styled.div`
  background: linear-gradient(180deg, #ff61a6 0%, #ff4d4d 100%);
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const LeftSection = styled.div`
  text-align: left;
`;

const QrSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QrSubtitle = styled.p`
  font-size: 20px;
  white-space: pre-wrap;
  text-align: center;
  padding-top: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 13rem;
`;

const LegalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;
`;

const LegalTextLeft = styled.a`
  font-size: 14px;
  text-align: left;
  flex: 1;
`;

const Title = styled.h2`
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
`;

const StoreIcons = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 20px;
  align-items: center;
`;

const StyledLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 21px;
  &:hover {
    color: #f1f1f1;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailText = styled.p`
  font-size: 20px;
  margin: 0;
`;

const EmailLink = styled.h1`
  font-size: 19px;
  text-decoration: underline;
  margin-top: 0;
`;
