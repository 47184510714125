import Categories from "../../components/Categories";
import Products from "../../components/Products";
import Promotions from "../../components/Promotions";
import Shops from "../../components/Shops";

const MainPage = () => {
  return (
    <>
      <Categories />
      <Promotions />
      <Shops />
      <Products />
    </>
  );
};

export default MainPage;
