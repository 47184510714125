import styled from "styled-components";
import { Button } from "antd";

const Categories = () => {
  return (
    <Content>
      <Item type="default">Электроника</Item>
      <Item type="default">Бытовая техника</Item>
      <Item type="default">Одежда</Item>
      <Item type="default">Обувь</Item>
      <Item type="default">Аксессуары</Item>
      <Item type="default">Красота</Item>
      <Item type="default">Еще</Item>
    </Content>
  );
};

export default Categories;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Item = styled(Button)`
  width: 160px;
  font-size: 13px;
  margin: 0 10px;
  background-color: #f1f1f1;
  border-radius: 30px;
  border: 0;
`;
