import styled from "styled-components";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import { SHOPS } from "../gqls/shop";
import { SERVER_URL } from "../utils";

const Shops = () => {
  const { data: shopsData } = useQuery(SHOPS, {
    fetchPolicy: "network-only",
  });

  let shops = (shopsData?.shops ?? []).slice(0, 8);

  return (
    <ShopsContainer>
      <ButtonsContainer>
        <GradientButton type="default">Магазины</GradientButton>
        <ActionButton type="default">Смотреть все</ActionButton>
      </ButtonsContainer>
      <ShopGrid>
        {shops?.map((shop: any, index: any) => (
          <ShopContainer key={shop.id}>
            <img
              src={`${SERVER_URL}/uploads/${shop.logo}`}
              alt={`${shop.name}`}
            />
          </ShopContainer>
        ))}
      </ShopGrid>
    </ShopsContainer>
  );
};

export default Shops;

// Styled components

const ShopsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbe9f0;
  padding-top: 30px;
  margin-top: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
`;

const ShopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 80%;
  margin-bottom: 20px;
`;

const ShopContainer = styled.div`
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; // Adjust padding to fit the design
  height: 200px;

  img {
    width: 100%; // Adjust width as per requirement
    height: 100%; // Adjust height as per requirement
    object-fit: fill; // Adjust object-fit as per requirement
  }
`;

const ActionButton = styled(Button)`
  width: 130px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 30px;
  border: 0;
`;

const GradientButton = styled(ActionButton)`
  background: linear-gradient(141.61deg, #a314e6 -32.31%, #e6141f 97.67%);
  color: #fff;
  &:hover {
    color: #f1f1f1 !important;
  }
`;
