import styled from "styled-components";
import { ConfigProvider, Layout as AntLayout } from "antd";

import Header from "./Header";
import Footer from "./Footer";

const { Content: AntContent } = AntLayout;

export const Layout = ({ children }: any) => {
  return (
    <ConfigProvider theme={{ token: { colorPrimaryHover: "#d1145e" } }}>
      <Provider>
        <AntLayout>
          <Header />
          <Content>
            <div className="content">{children}</div>
          </Content>
          <Footer />
        </AntLayout>
      </Provider>
    </ConfigProvider>
  );
};

const Provider = styled(AntLayout)`
  min-height: 100vh;
`;

const Content = styled(AntContent)`
  background: #fff;
  padding-bottom: 50px;
  @media only screen and (max-width: 480px) {
    margin: 10px 0;
  }
  .content {
    min-height: 100%;
    @media only screen and (max-width: 480px) {
      padding: 20px 14px;
    }
  }
`;
