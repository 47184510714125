import { ApolloProvider } from "@apollo/client";
import { Layout } from "./components/Layout";
import MainPage from "./pages/main";
import { apolloClient } from "./utils/apollo-client";
import { useMediaQuery } from "react-responsive";
import MobileApp from "./components/MobileApp";

function App() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <ApolloProvider client={apolloClient}>
      {isMobile ? (
        <MobileApp />
      ) : (
        <Layout>
          <MainPage />
        </Layout>
      )}
    </ApolloProvider>
  );
}

export default App;
