import styled from "styled-components";
import { Button, Spin } from "antd";
import { Carousel } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { PROMOTIONS } from "../gqls/promotion";
import { SERVER_URL } from "../utils";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Promotions = () => {
  const { data: promotionsInfo, loading } = useQuery(PROMOTIONS, {
    fetchPolicy: "network-only",
  });

  let promotions = promotionsInfo?.promotions;
  return (
    <PromotionsContainer>
      <ButtonsContainer>
        <GradientButton type="default">Акции</GradientButton>
        <ActionButton type="default" onClick={() => { }}>
          Смотреть все
        </ActionButton>
      </ButtonsContainer>

      <PromotionBox>
        {loading ? (
          <Spin size="large" />
        ) : promotions.length === 0 ? (
          <>Нет акций</>
        ) : (
          <Carousel data-bs-theme="dark">
            {promotions.map((item: any) => (
              <Carousel.Item key={item.id} style={{ height: "350px" }}>
                <StyledImage
                  src={`${SERVER_URL}/uploads/${item.image}`}
                  alt="promotion"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </PromotionBox>
    </PromotionsContainer>
  );
};

export default Promotions;
const PromotionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const StyledImage = styled.img`
  width: 100%; // Full width of the container
  height: 350px; // Fixed height
  object-fit: fill; // Covers the area, might crop some parts of the image
  object-position: center; // Center the image within the element
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
`;

const PromotionBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 350px;
  border: 1px solid black;
  border-radius: 30px;
  overflow: hidden; // This will ensure that the image does not extend beyond the box
`;

const ActionButton = styled(Button)`
  width: 130px;
  font-size: 15px;
  background-color: #f1f1f1;
  border-radius: 30px;
  border: 0;
`;

const GradientButton = styled(ActionButton)`
  background: linear-gradient(141.61deg, #a314e6 -32.31%, #e6141f 97.67%);
  color: #fff;
  &:hover {
    color: #f1f1f1 !important;
  }
`;
