import { gql } from "@apollo/client";

const PRODUCT_FRAGMENT = gql`
  fragment ProductReturn on Product {
    id
    createdAt
    updatedAt
    name
    images
    price
    fullPrice
    description
    category {
      id
      name
    }
    subcategory {
      id
      name
    }
    shop {
      id
      name
      logo
      description
      phone
      email
      deliveryExists
      deliveryDeadline
      deliveryMinAmount
      reviewsCount
      rating
    }
    ordersCount
    reviewsCount
    rating
    colors
    sizes
  }
`;

export const PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCT($where: ProductWhereInput!) {
    product(where: $where) {
      ...ProductReturn
    }
  }
`;

export const PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCTS($where: ProductsWhereInput) {
    products(where: $where) {
      products {
        ...ProductReturn
      }
      count
    }
  }
`;

export const CART_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query CART_PRODUCTS {
    cartProducts {
      ...ProductReturn
    }
  }
`;

export const FAVOURITE_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query FAVOURITE_PRODUCTS {
    favouriteProducts {
      ...ProductReturn
    }
  }
`;

export const ORDER_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query ORDER_PRODUCTS($where: OrderProductsWhereInput!) {
    orderProducts(where: $where) {
      ...ProductReturn
    }
  }
`;

export const PRODUCTS_FOR_REVIEW = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCTS_FOR_REVIEW {
    productsForReview {
      ...ProductReturn
    }
  }
`;

export const CLOSED_REVIEW_PRODUCTS = gql`
  mutation CLOSED_REVIEW_PRODUCTS($data: ClosedReviewProductsInput) {
    closedReviewProducts(data: $data)
  }
`;
