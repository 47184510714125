import styled from "styled-components";

import { Row, Col, Card, Button, Spin } from "antd";
import { HeartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { PRODUCTS } from "../gqls/product";
import { useQuery } from "@apollo/client";
import { SERVER_URL } from "../utils";
import { useState } from "react";

const FoodContainerGrid = ({ products }: { products: any[] }) => {
  return (
    <FoodContainer>
      <Row gutter={[16, 16]} wrap={true}>
        {products.map((item: any) => (
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={5}
            xl={5}
            key={item.id}
            style={{
              width: `calc(20% - ${16}px)`,
              flex: "0 0 auto",
            }}
          >
            <Card
              hoverable
              cover={
                <img
                  alt={item.title}
                  src={`${SERVER_URL}/uploads/${item.images}`}
                  style={{ width: "230px", height: "230px", objectFit: "fill" }}
                />
              }
              actions={[
                <HeartOutlined key="like" />,
                <ShoppingCartOutlined key="cart" />,
              ]}
            >
              <Card.Meta title={item.name} description={`₽ ${item.price}`} />
            </Card>
          </Col>
        ))}
      </Row>
    </FoodContainer>
  );
};

const Products = () => {
  const [productCount, setProductCount] = useState(10);
  const { data: productsData, loading } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
  });

  let products = [];

  if (productsData && productsData.products && productsData.products.products) {
    products = productsData.products.products.slice(0, productCount);
  }

  const showMoreProducts = () => {
    setProductCount((prevCount: number) => prevCount + 10);
  };
  return (
    <Content>
      <TabsContainer>
        <Item type="default">Популярное</Item>
        <Item type="default">Yakutia</Item>
        <Item type="default">Новинки</Item>
        <Item type="default">Распродажа</Item>
      </TabsContainer>
      {loading ? (
        <CenteredSpin>
          <Spin size="large" />
        </CenteredSpin>
      ) : (
        <FoodContainerGrid products={products} />
      )}

      <ShowMoreContainer>
        <ShowMore type="default" onClick={showMoreProducts}>
          Показать еще
        </ShowMore>
      </ShowMoreContainer>
    </Content>
  );
};

export default Products;

const Content = styled.div`
  padding: 0 5%;
  margin-top: 40px;
`;

const FoodContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
`;

const Item = styled(Button)`
  min-width: 120px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  background-color: #f1f1f1;
  border-radius: 30px;
  border: 0;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ShowMore = styled(Button)`
  width: 400px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  background-color: #f1f1f1;
  border-radius: 30px;
  border: 0;
`;

const CenteredSpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; // Adjust the height as needed, 80vh will cover most of the viewport height
`;
