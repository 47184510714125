import { gql } from "@apollo/client";

const SHOP_FRAGMENT = gql`
  fragment ShopReturn on Shop {
    id
    name
    logo
    description
    phone
    email
    createdAt
    updatedAt
    deliveryExists
    deliveryDeadline
    deliveryMinAmount
    reviewsCount
    rating
    blocked
  }
`;

export const SHOPS = gql`
  ${SHOP_FRAGMENT}
  query SHOPS($data: ShopsInput) {
    shops(data: $data) {
      ...ShopReturn
    }
  }
`;

export const SHOP = gql`
  ${SHOP_FRAGMENT}
  query SHOP($where: ShopWhereInput!) {
    shop(where: $where) {
      ...ShopReturn
    }
  }
`;
